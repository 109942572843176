import React from "react"
// import "../../node_modules/@fortawesome/fontawesome-pro/css/all.min.css"
import LandingPage from "../components/landing-page/landingpage"
import Layout from "../components/Layout"
import SEO from "../components/seo"
import { GlobalStateProvider } from "../State/global-state/global-state"
import "./mystyles.scss"
// const checkRightPageToLoad = params => {
//   var authUser = ""
//   if (typeof window !== "undefined") {
//     authUser = localStorage.getItem("authUser")
//   }
//   //Commented the following lines to provide the ability for a signed in user to navigate to landing page.
//   // if (authUser) {
//   //   // If logged in, redirect to the App home page.
//   //   navigate(`/app/dashboard`)
//   //   return null
//   // }
// }
if (process.env.NODE_ENV !== "development") console.log = () => {}
//console.log = () => {}
const IndexPageBase = () => (
  <Layout>
    <SEO pageTitle="Home" title="voyayge.com" />
    {/* {checkRightPageToLoad()} */}
    <LandingPage />
  </Layout>
)
const IndexPage = () => {
  return (
    <GlobalStateProvider>
      <IndexPageBase />
    </GlobalStateProvider>
  )
}
export default IndexPage
